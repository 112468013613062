var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-page" },
    [
      _c("h1", { staticClass: "heading" }, [_vm._v("Modal window")]),
      _c("p", { staticClass: "modal-page__cta" }, [
        _c(
          "button",
          {
            staticClass: "button button--01",
            on: {
              click: function($event) {
                _vm.showDemoModal = true
              }
            }
          },
          [_vm._v(" Open demo confirm modal ")]
        )
      ]),
      _c("p", [
        _c(
          "button",
          {
            staticClass: "button button--01",
            on: {
              click: function($event) {
                _vm.showBasicDemoModal = true
              }
            }
          },
          [_vm._v(" Open basic demo confirm modal ")]
        )
      ]),
      _c(
        "ModalWindow",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDemoModal,
              expression: "showDemoModal"
            }
          ],
          attrs: { title: "Console message" },
          on: { onConfirm: _vm.confirmDemoModal, onCancel: _vm.cancelDemoModal }
        },
        [
          _c("p", [
            _vm._v("Are you sure you want to display a console message?")
          ])
        ]
      ),
      _c("ModalWindow", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBasicDemoModal,
            expression: "showBasicDemoModal"
          }
        ],
        on: {
          onConfirm: function($event) {
            _vm.showBasicDemoModal = false
          },
          onCancel: function($event) {
            _vm.showBasicDemoModal = false
          }
        }
      }),
      _c("p", [
        _c(
          "button",
          {
            staticClass: "button button--02",
            on: { click: _vm.showConfirmModal }
          },
          [_vm._v(" Open modalConfirm ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }