var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-window--fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal-window",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.cancel($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-window__container",
            attrs: {
              role: "dialog",
              "aria-labelledby": "modalWindowTitle",
              "aria-describedby": "modalWindowDescription"
            }
          },
          [
            _c("div", { staticClass: "modal-window__header" }, [
              _c(
                "h2",
                {
                  staticClass: "modal-window__title",
                  attrs: { id: "modalWindowTitle" }
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "modal-window__body",
                attrs: { id: "modalWindowDescription" }
              },
              [_vm._t("default", [_c("p", [_vm._v("Are you sure?")])])],
              2
            ),
            _vm.showModalFooter
              ? _c("div", { staticClass: "modal-window__footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button--03",
                      attrs: { "aria-label": "Close modal" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.cancel($event)
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button button--02",
                      attrs: { "aria-label": "Close modal" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.confirm($event)
                        }
                      }
                    },
                    [_vm._v(" OK ")]
                  )
                ])
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }